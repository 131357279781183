import React from 'react'
import { Link, graphql } from "gatsby"

import Layout from '../components/layout'

export default ({ data }) => (
  <Layout>
    <>
      <div className="row mt-3">
        <div className="col-12">
          <h1>Nieuws</h1>
        </div>
      </div>

      <div className="row mt-2">
        {data.allWordpressWpNews && data.allWordpressWpNews.edges.map(({ node }) => (

          <div className="col-lg-4 mt-3" key={node.slug}>
            <div className="row">
              <div className="col-12 mb-1">
                <h4 className="">{node.date}</h4>
              </div>
              <div className="col-12">
                <h3 className="subtitle mb-1" dangerouslySetInnerHTML={{__html: node.title}}></h3>
                <p>
                  <Link to={`/nieuws/${node.slug}/`}>Lees meer...</Link>
                </p>
              </div>
            </div>
          </div>

        ))}
        <div className="clearfix"></div>
      </div>
    </>
  </Layout>
)

export const query = graphql`
  {
  	allWordpressWpNews {
      edges {
        node {
          id
          wordpress_id
          title
          date(formatString: "MMMM DD, YYYY")
          slug
        }
      }
    }
  }
`
